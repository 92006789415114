<template>
    <div>
        <DxButton
            type="normal"
            styling-mode="text"
            icon="mdi mdi-filter-variant-remove"
            style="position: fixed; top: 13px; right: 14px"
            @click="xoaLoc"
        />
        <DxValidationGroup ref="formValidation">
            <div class="pb-3">
                <span class="font-12">Trạng thái (*)</span>
                <DxSelectBox
                    v-model="model.TrangThai"
                    :data-source="DanhSachTrangThai"
                    value-expr="Id"
                    display-expr="text"
                    styling-mode="underlined"
                    validationMessageMode="always"
                >
                    <!-- :onValueChanged="onChangeTrangThai" -->
                    <DxValidator>
                        <DxRequiredRule
                            message="Trạng thái không được bỏ trống!"
                        />
                    </DxValidator>
                </DxSelectBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Tìm kiếm</span>
                <DxTextBox
                    v-model="model.TimKiem"
                    validationMessageMode="always"
                    mode="text"
                    styling-mode="underlined"
                >
                    <DxValidator>
                        <DxStringLengthRule
                            :max="128"
                            message="Tìm kiếm không được vượt quá 128 ký tự!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Ngày xuất bến kế hoạch</span>
                <DxDateBox
                    v-model="model.NgayXuatBenKH"
                    displayFormat="dd/MM/yyyy"
                    :openOnFieldClick="true"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    pickerType="rollers"
                    type="date"
                    :onValueChanged="onChangeNgayXuatBenKH"
                    ><DxValidator>
                        <DxRequiredRule
                            message="Ngày xuất bến kế hoạch không được bỏ trống!"
                    /></DxValidator>
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>
                </DxDateBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Tuyến vận chuyển</span>
                <DxSelectBox
                    ref="TuyenVanChuyen"
                    v-model="model.TuyenVanChuyen"
                    :items="data.TuyenVanChuyen"
                    :displayExpr="
                        (x) =>
                            x
                                ? `${x.tenTuyen} ${
                                      x.maTuyen ? `(${x.maTuyen})` : ''
                                  }`
                                : ``
                    "
                    value-expr="idTuyen"
                    styling-mode="underlined"
                    no-data-text="Không có dữ liệu!"
                    :dropDownOptions="{
                        onContentReady: onContentReady,
                        width: '100%',
                    }"
                    :onItemClick="onItemClickTuyen"
                    :onChangeValue="changeTuyenVanChuyen"
                >
                </DxSelectBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Giờ xuất bến kế hoạch</span>
                <DxDropDownBox
                    ref="filterBox_GioXuatBenKH"
                    v-model:value="model.GioXuatBenKH"
                    :items="data.GioXuatBenKH"
                    display-expr="gio"
                    value-expr="idGio"
                    placeholder="Lựa chọn..."
                    styling-mode="underlined"
                    class="selectbox-style clear-padding"
                    validationMessageMode="always"
                    :dropDownOptions="{
                        onContentReady: onContentReady,
                        width: '100%',
                    }"
                >
                    <template #content>
                        <DxList
                            ref="tree-view"
                            :data-source="data.GioXuatBenKH"
                            :show-selection-controls="true"
                            display-expr="gio"
                            key-expr="idGio"
                            selection-mode="all"
                            select-all-mode="allPages"
                            no-data-text="Không có dữ liệu!"
                            v-model:selectedItemKeys="model.GioXuatBenKH"
                        >
                            <template #item="{ data: item }">
                                <p :title="item.gio">
                                    {{ item.gio }}
                                </p>
                            </template>
                        </DxList>
                    </template>
                </DxDropDownBox>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import {
    DxDateBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
    DxTextBox,
} from "devextreme-vue";
import { DxDropDownBox } from "devextreme-vue/drop-down-box";
import { DxList } from "devextreme-vue/list";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";
import LenhDienTu from "../../../../class/LenhDienTu";

export default {
    layout: "application",
    components: {
        DxButton,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxSelectBox,
        DxDropDownBox,
        DxList,
        DxTextBox,
        DxStringLengthRule,
    },
    props: {
        filter: {
            type: Object,
            default: () => ({
                TrangThai: "0",
                NgayXuatBenKH: new Date(),
                TuyenVanChuyen: null,
                TimKiem: null,
                GioXuatBenKH: [],
            }),
        },
    },
    data() {
        return {
            model: {
                NgayXuatBenKH: new Date(),
                TuyenVanChuyen: null,
                TimKiem: null,
                GioXuatBenKH: [],
                TrangThai: "0",
            },
            data: {
                TuyenVanChuyen: [],
                GioXuatBenKH: [],
            },
            DanhSachTrangThai: [
                {
                    Id: this.$t("TrangThaiLenh_Loc.TatCa"),
                    text: "Tất cả",
                },
                {
                    Id: this.$t("TrangThaiLenh_Loc.ChuaThucHien"),
                    text: "Chưa thực hiện",
                },
                {
                    Id: this.$t("TrangThaiLenh_Loc.DangThucHien"),
                    text: "Đang thực hiện",
                },
                {
                    Id: this.$t("TrangThaiLenh_Loc.HoanThanh"),
                    text: "Đã hoàn thành",
                },
                {
                    Id: this.$t("TrangThaiLenh_Loc.KhongHoanThanh"),
                    text: "Không hoàn thành",
                },
            ],
            CheckLayDsTuyen: false,
        };
    },
    computed: {
        GioXuatBenKH_selected: {
            get() {
                // return this.data.GioXuatBenKH.filter(x =>);
            },
        },
    },
    methods: {
        selectedItemKeys(e) {},
        onContentReady(e) {
            var html = e.component.content();
            html.style.cssText = "padding: 8px !important";
        },
        onItemClickTuyen(e) {
            if (this.model.TuyenVanChuyen) {
                this.LayDSGioXuatBenTheoNgay(e.itemData.idTuyen);
            }
        },
        onChangeNgayXuatBenKH() {
            if (this.CheckLayDsTuyen == false) {
                this.LayDSTuyenVanChuyenTheoNgay();
            }
        },
        async LayDSTuyenVanChuyenTheoNgay() {
            this.model.TuyenVanChuyen = null;
            this.model.GioXuatBenKH = [];
            this.data.TuyenVanChuyen = [];
            this.data.GioXuatBenKH = [];
            try {
                let rs = await new LenhDienTu().LayDSTuyenVanChuyenTheoNgay(
                    this.model.NgayXuatBenKH || new Date()
                );
                this.CheckLayDsTuyen = false;
                if (!rs) {
                    throw new Error();
                }
                if (!rs.StatusCode === 200) {
                    return;
                }
                this.data.TuyenVanChuyen = rs.Data.data;
                this.model.TuyenVanChuyen = this.filter.TuyenVanChuyen;
            } catch (error) {
                this.CheckLayDsTuyen = false;
                this.$emit("thongBaoLocDL", "LoiHeThong", error);
            }
        },
        async LayDSGioXuatBenTheoNgay(idTuyen) {
            this.data.GioXuatBenKH = [];
            try {
                let rs = await new LenhDienTu().LayDSGioXuatBenTheoNgay(
                    this.model.NgayXuatBenKH,
                    idTuyen
                );
                if (!rs) {
                    throw new Error();
                }
                if (!rs.StatusCode === 200) {
                    return this.$emit(
                        "thongBaoLocDL",
                        "LoiMayChu",
                        rs.Data.message
                    );
                }
                this.data.GioXuatBenKH = rs.Data.data;

                await new Promise(function (ok) {
                    setTimeout(() => {
                        ok();
                    }, 100);
                });
                this.model.GioXuatBenKH = this.filter.GioXuatBenKH;
            } catch (error) {
                this.$emit("thongBaoLocDL", "LoiHeThong", error);
            }
        },
        changeTuyenVanChuyen() {
            if (!this.model.TuyenVanChuyen) {
                return;
            }
            this.LayDSGioXuatBenTheoNgay(this.model.TuyenVanChuyen);
        },
        xoaLoc() {
            this.model.TrangThai = "0";
            this.model.GioXuatBenKH = [];
            this.model.TuyenVanChuyen = null;
            this.model.TimKiem = null;
            this.$emit("thongBaoLocDL", "ClearFilter");
        },
    },
    created() {},
    mounted() {
        setTimeout(() => {
            if (this.filter.NgayXuatBenKH) {
                this.model.TrangThai = this.filter.TrangThai;
                this.model.TimKiem = this.filter.TimKiem;
                this.model.NgayXuatBenKH = this.filter.NgayXuatBenKH;
                this.model.TuyenVanChuyen = this.filter.TuyenVanChuyen;
                this.changeTuyenVanChuyen();
            }
            this.CheckLayDsTuyen = true;
            this.LayDSTuyenVanChuyenTheoNgay();
        }, 100);
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>